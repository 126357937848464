import React from "react";
import up from "./images/move.png";
import { Grid } from "@mui/material";
import Team from "./Team";

function About() {
  return (
    <div id="about" style={{ marginTop: "-100px" }}>
      <img src={up} style={{ width: "100%" }} />
      <div
        style={{
          backgroundColor: "#0a0a0a",
          paddingTop: "100px",
          paddingBottom: "100px",
        }}
      >
        <div className="container about_bg" style={{ position: "relative" }}>
          <Grid container>
            <Grid item md={12} lg={12} style={{ paddingRight: "20px" }}>
              <h1 className="FAQ_TEXTs">
                <br />
                About Clutch
              </h1>
              <h1 className="about_under">
                <br />
                At Clutch, we are dedicated to revolutionizing the meme coin
                market by prioritizing convenience and safety for our customers.
                Our mission is to make the exciting world of meme coins
                accessible to everyone, regardless of their level of expertise.
                We believe that anyone should be able to dive into the meme coin
                universe and enjoy its benefits without needing professional
                knowledge.
                <br />
                <br />
                Our commitment to our customers is unwavering. We actively
                listen to your feedback and continuously strive to enhance our
                platform, ensuring it remains at the forefront of the industry.
                Your opinions are invaluable to us, and they guide our efforts
                to create a perfect and leading platform that meets your needs
                and exceeds your expectations.
                <br />
                <br />
                We understand that the future of meme coins is in your hands.
                That's why we are dedicated to providing you with the best
                possible experience, empowering you to explore, invest, and
                thrive in the meme coin market. Our platform is designed with
                your convenience and safety in mind, offering a seamless and
                secure environment for all your meme coin activities.
                <br />
                <br />
                Join us on this exciting journey as we continue to innovate and
                lead the way in the meme coin market. Together, we can shape the
                future of meme coins and create a vibrant, inclusive community
                where everyone can participate and benefit.
                <br />
                <br />
                Thank you for being a part of Clutch. Your trust and support
                drive us to keep pushing the boundaries and delivering
                excellence in everything we do.
                <br />
                <br />
                LET'S BUILD THE FUTURE OF MEME COINS TOGETHER!
              </h1>
              <br />
            </Grid>
            <img
              src="./images/coins.png"
              style={{
                width: "240px",
                position: "absolute",
                top: "-80px",
                right: 0,
              }}
            />
            <Grid item md={12} lg={12}>
              <img src="./images/about.png" style={{ width: "100%" }} />
              <br />
              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default About;
