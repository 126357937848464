import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import robo from './images/memeshion-robot.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Slider1() {
  useEffect(() => {
    // Animation for the floating effect
    gsap.fromTo(
      '.floating-element',
      { y: -50, opacity: 0 }, // Start from above and transparent
      {
        y: 0, // Move to original position
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: '.floating-element', // Trigger animation when this element enters the viewport
          start: 'top 80%', // Start animation when top of the element hits 80% of the viewport height
          end: 'top 20%', // End animation when the top of the element hits 20% of the viewport height
          toggleActions: 'play none none reverse', // Play on enter and reverse on exit
          once: true // Only animate once on initial load
        }
      }
    );
  }, []);

  return (
    <div className="backofslide3">
      <div className="glass-effect"></div>
    
      <div className="container">     <br /> <br /> <br /><br /><br /><br />
        <Grid container spacing={1}>
        <Grid item md={6} lg={6} xs={12} sm={12}> <br/>
            <div className="top">
            
             
              <h4 className="FAQ_TEXTt floating-element">volume BOT
              </h4>
              <h4 className="slider_under floating-element">
              Fast speed


              </h4>
              <h4 className="slider_under floating-element">Low fee
              </h4>

              <h4 className="slider_under floating-element">
              Make token hot</h4>
              <br />
             
            </div>
          </Grid>  <br/>
          <Grid item md={6} lg={6} xs={12} sm={12}>
         
            <div className="top">
          
             
             <img src={robo} style={{width:"60%"}}/>
             
            </div>
          </Grid>

        </Grid>
      </div>
    </div>
  );
}

export default Slider1;
