import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Import Autoplay
import { useEffect, useRef } from "react";
import gsap from "gsap";

import Solanabottest from "./images/solana_bot.png";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

export default function App() {
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  return (
    <div className="slider-container fix_slider" id="home">
      <button
        ref={prevBtnRef}
        className="custom-prev-btn"
        style={{ position: "relative", bottom: "0px" }}
      >
        ←
      </button>
      <Swiper
        loop={true}
        slidesPerView={4} // Show 4 slides at a time
        spaceBetween={20} // Space between the slides
        autoplay={{ delay: 3000, disableOnInteraction: false }} // Add autoplay with 3-second delay
        navigation={{
          nextEl: ".custom-next-btn",
          prevEl: ".custom-prev-btn",
        }}
        modules={[Navigation, Autoplay]} // Add Autoplay module
        className="mySwiperms"
      >
        <SwiperSlide>
          <>
            <motion.div className="card">
              <div className="card_title__container">
                <span className="card_title">Solana Volume Bot</span>
                <motion.img
                  src="./images/tg-sol-volume-bot.png"
                  style={{
                    width: "calc(100% + 1rem)",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginLeft: "-0.5rem",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                />
              </div>
              <motion.button
                className="button"
                onClick={() =>
                  window.open("https://t.me/ClutchSolanaVolumeBot", "_blank")
                }
              >
                GET STARTED
              </motion.button>
            </motion.div>
          </>
        </SwiperSlide>
        <SwiperSlide>
          <>
            <motion.div className="card">
              <div className="card_title__container">
                <span className="card_title">Solana Holder Bot</span>
                <motion.img
                  src="./images/tg-sol-holder-bot.png"
                  style={{
                    width: "calc(100% + 1rem)",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginLeft: "-0.5rem",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                />
              </div>
              <motion.button
                className="button"
                onClick={() =>
                  window.open("https://t.me/ClutchSolanaHolderBot", "_blank")
                }
              >
                GET STARTED
              </motion.button>
            </motion.div>
          </>
        </SwiperSlide>
        <SwiperSlide>
          <>
            <motion.div className="card">
              <div className="card_title__container">
                <span className="card_title">Moonshot Volume Bot</span>
                <motion.img
                  src="./images/coming-soon-bot.png"
                  style={{
                    width: "calc(100% + 1rem)",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginLeft: "-0.5rem",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                />
              </div>
              <motion.button
                className="button"
                style={{
                  backgroundColor: "#db0039",
                }}
              >
                COMING SOON
              </motion.button>
            </motion.div>
          </>
        </SwiperSlide>
        <SwiperSlide>
          <>
            <motion.div className="card">
              <div className="card_title__container">
                <span className="card_title">Solana Buy/Sell Bot</span>
                <motion.img
                  src="./images/coming-soon-bot.png"
                  style={{
                    width: "calc(100% + 1rem)",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginLeft: "-0.5rem",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8 }}
                />
              </div>
              <motion.button
                className="button"
                style={{
                  backgroundColor: "#db0039",
                }}
              >
                COMING SOON
              </motion.button>
            </motion.div>
          </>
        </SwiperSlide>
      </Swiper>
      <button
        ref={nextBtnRef}
        className="custom-next-btn"
        style={{ position: "relative", bottom: "0px" }}
      >
        →
      </button>
    </div>
  );
}
