import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Import Autoplay
import { useEffect, useRef } from "react";
import gsap from "gsap";
import Slider1 from "./Slider1";
import Slider2 from "./Slider2";
import Slider3 from "./Slider3";
import Upside from "./images/upside.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

export default function App() {
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  // GSAP animation for buttons
  useEffect(() => {
    gsap.fromTo(
      [prevBtnRef.current, nextBtnRef.current],
      { opacity: 0, scale: 0.5 }, // Initial state: hidden and scaled down
      { opacity: 1, scale: 1, duration: 0.5, ease: "power1.out", stagger: 0.2 } // Final state: visible and full size
    );
  }, []);

  const handleHoverIn = () => {
    gsap.to([prevBtnRef.current, nextBtnRef.current], {
      opacity: 1,
      scale: 1,
      duration: 0.3,
      ease: "power1.out",
    });
  };

  const handleHoverOut = () => {
    gsap.to([prevBtnRef.current, nextBtnRef.current], {
      opacity: 0,
      scale: 0.5,
      duration: 0.3,
      ease: "power1.out",
    });
  };

  return (
    <div
      className="slider-container fix_slider"
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      id="home"
    >
      <button ref={prevBtnRef} className="custom-prev-btn">
        ←
      </button>
      <Swiper
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }} // Add autoplay with 3-second delay
        navigation={{
          nextEl: ".custom-next-btn",
          prevEl: ".custom-prev-btn",
        }}
        modules={[Navigation, Autoplay]} // Add Autoplay module
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            alt=""
            src="./images/launch-token.png"
            style={{ width: "100%" }}
          />
        </SwiperSlide>
        <SwiperSlide>
        <img
            alt=""
            src="./images/bots.png"
            style={{ width: "100%" }}
          />
        </SwiperSlide>
      </Swiper>
      <button ref={nextBtnRef} className="custom-next-btn">
        →
      </button>
    </div>
  );
}
