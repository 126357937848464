import React from "react";
import { useMediaQuery } from "@mui/material";
import back from "./back.mp4";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Home = () => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source src={back} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Content */}
      <div style={{ position: "relative", zIndex: 1 }}>
        <Desktop />
        {/* {!smallScreen && <Desktop />} */}
        {/* {smallScreen && <Mobile />} */}
      </div>
    </div>
  );
};

export default Home;
