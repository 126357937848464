import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Slider1Marque from './Slider1Marque';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Slider1() {
  useEffect(() => {
    // Animation for the floating effect
    gsap.fromTo(
      '.floating-element',
      { y: -50, opacity: 0 }, // Start from above and transparent
      {
        y: 0, // Move to original position
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: '.floating-element', // Trigger animation when this element enters the viewport
          start: 'top 80%', // Start animation when top of the element hits 80% of the viewport height
          end: 'top 20%', // End animation when the top of the element hits 20% of the viewport height
          toggleActions: 'play none none reverse', // Play on enter and reverse on exit
          once: true // Only animate once on initial load
        }
      }
    );
  }, []);

  return (
    <div className="backofslide1">
      <div className="glass-effect"></div>
      <br /> <br /> <br /><br /><br /><br />
      <div className="container">
        <Grid container spacing={1}>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <div className="top">
              <br /> <br /><h4 className="FAQ_TEXTt floating-element">Our Team
              </h4> <br />
              <Slider1Marque /> <br />
              
              <h4 className="slider_under floating-element">
              Meme support


              </h4>
              <h4 className="slider_under floating-element">Multi chain</h4>
              <br />
              <div className="centeritall">
                <button className="button">
                  <span className="button-content">Get Started</span>
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Slider1;
