import React from "react";
import { Grid } from "@mui/material";

function Launchpad() {
  return (
    <div className="back_topm" id="Launchpad">
      <div style={{ paddingTop: "100px" }}>
        <Grid container spacing={2}>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <div className="btc">
              <div
                className="BACKPLANET"
                style={{
                  position: "relative",
                  backgroundImage: "url(./images/planet.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  aspectRatio: 1,
                }}
              >
                <img
                  alt=""
                  src="./images/eth256.png"
                  style={{
                    width: "20%",
                    position: "absolute",
                    top: "15%",
                    left: "20%",
                  }}
                  className="upand_down"
                />
                <img
                  alt=""
                  src="./images/sol256.png"
                  style={{
                    width: "15%",
                    position: "absolute",
                    top: "25%",
                    right: "20%",
                  }}
                  className="upand_down1"
                />
                <img
                  alt=""
                  src="./images/tron256.png"
                  style={{
                    width: "15%",
                    position: "absolute",
                    top: "38%",
                    left: "12%",
                  }}
                  className="upand_down2"
                />
                <img
                  alt=""
                  src="./images/ton256.png"
                  style={{
                    width: "15%",
                    position: "absolute",
                    top: "50%",
                    right: "15%",
                  }}
                  className="upand_down3"
                />
                <img
                  alt=""
                  src="./images/bnb256.png"
                  style={{
                    width: "15%",
                    position: "absolute",
                    bottom: "15%",
                    right: "20%",
                  }}
                  className="upand_down4"
                />
                <img
                  alt=""
                  src="./images/base256.png"
                  style={{
                    width: "15%",
                    position: "absolute",
                    bottom: "20%",
                    left: "20%",
                  }}
                  className="upand_down3"
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            style={{ padding: "10px 20px" }}
          >
            <div>
              <h5 className="solana_launch">Solana Launchpad</h5>
              <p className="solana_launcht">
                Our Solana Token Launchpad is a robust platform designed to
                simplify and enhance your token management on the Solana
                blockchain. <br />
                <br />
                Key features include: <br />• Create SPL Tokens: Effortlessly
                generate new SPL tokens. <br />• Revoke Mint Authority: Secure
                your tokens by revoking mint authority as needed. <br />• Freeze
                Authority: Maintain control with the ability to freeze tokens.{" "}
                <br />• Create OpenBook Market: Enable trading by creating
                OpenBook Market for your tokens. <br />• Add Liquidity Pools
                (LP) & Sniping: Boost liquidity using sniping tokens with Jito
                bundling. <br />
                <br />
                Experience seamless and secure token management with our
                all-in-one Solana Token Launchpad.
                <br />
                <br />
                <button
                  className="button"
                  style={{
                    backgroundColor: "#ee37ff",
                  }}
                  onClick={() =>
                    window.open("https://sol.clutchpad.io", "_blank")
                  }
                >
                  <span className="button-content">Get Started</span>
                </button>
              </p>
              <hr class="lines" />
            </div>
            <div>
              <h5 className="solana_launch1">EVM Launchpad</h5>
              <p className="solana_launcht">
                Our EVM Token Launchpad is a versatile platform supporting
                <a style={{ color: "#0091ff" }}> Ethereum</a>,{" "}
                <a style={{ color: "#0091ff" }}>Base</a>, and{" "}
                <a style={{ color: "#0091ff" }}>Binance Smart Chain</a>. <br />
                <br />
                Key features include: <br />• Create ERC-20 and BEP-20 Tokens:
                Easily generate new tokens on Ethereum and Binance Smart Chain.{" "}
                <br />• Revoke Mint Authority: Enhance security by revoking mint
                authority when necessary. <br />• Freeze Authority: Maintain
                control with the ability to freeze tokens. <br />• Add Liquidity
                Pools (LP): Improve liquidity by adding LP. <br />• Launch
                Tokens with Sniping Tokens: Buy tokens at first with sniping.{" "}
                <br />
                <br />
                Experience seamless token creation and management with our
                comprehensive EVM Token Launchpad.
                <br />
                <br />
                <button
                  className="button"
                  style={{ backgroundColor: "#eaab00" }}
                  onClick={() =>
                    window.open("https://evm.clutchpad.io", "_blank")
                  }
                >
                  <span className="button-content">Get Started</span>
                </button>
              </p>
              <hr class="lines" />
            </div>
            <div>
              <h5 className="solana_launch2">Tron Launchpad</h5>
              <p className="solana_launcht">
                Coming soon..
                <br />
                <br />
                {/* <button
                  className="button"
                  style={{ backgroundColor: "#ff3e3e" }}
                >
                  <span className="button-content">Get Started</span>
                </button> */}
              </p>
              <hr class="lines" />
            </div>
            <div>
              <h5 className="solana_launch3">Ton Launchpad</h5>
              <p className="solana_launcht">
                Coming soon..
                <br />
                <br />
                {/* <button
                  className="button"
                  style={{ backgroundColor: "#3ed5ff" }}
                >
                  <span className="button-content">Get Started</span>
                </button> */}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Launchpad;
