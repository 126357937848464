import React from "react";
import image from "./images/down.svg";
import { Grid } from "@mui/material";
import SliderBot from "./BotsSlider";

function Bots() {
  return (
    <div id="Bots">
      <div className="backofBots" style={{ paddingTop: "100px" }}>
        <div className="container">
          <h1 className="FAQ_TEXTbot">Telegram Bots </h1>
          <div style={{ marginTop: "50px" }}>
            <SliderBot />
          </div>
        </div>
      </div>
      <img src={image} style={{ width: "100%" }} />
    </div>
  );
}

export default Bots;
