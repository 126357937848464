import React from "react";
import logo from "./images/logo.png";
import { Grid } from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
function Footer() {
  return (
    <div>
      <div className="footer-bottom-wrap">
        <div className="container">
          <Grid container spacing={1}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <img src="./logo192-T.png" style={{ width: "200px" }} />
              <br /> <br />
              <h5 className="all_righ">
                {" "}
                © 2024 CLUTCH. All Rights Reserved
              </h5>
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12} className="makeitend">
              <br />
              <div style={{ display: "flex" }} className="makeitend">
                <a href="#home" className="all_righs">
                  Home
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="#about" className="all_righs">
                  About
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="#Launchpad" className="all_righs">
                  Launchpad
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="#Bots" className="all_righs">
                  Bots
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="#Faq" className="all_righs">
                  Faq
                </a>
                &nbsp;&nbsp;
              </div>
              <br /> <br />
              <br />
              {/* <div
                style={{ display: "flex", position: "relative", top: "10px" }}
                className="makeitend"
              >
                <a href="" className="all_righ">
                  <FaXTwitter style={{ fontSize: "25px" }} />
                </a>
                &nbsp;&nbsp;
                <a href="" className="all_righ">
                  <FaTelegramPlane style={{ fontSize: "25px" }} />
                </a>
                &nbsp;&nbsp;
                <a href="" className="all_righ">
                  <MdAttachEmail style={{ fontSize: "25px" }} />
                </a>
              </div> */}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Footer;
