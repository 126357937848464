import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import sample from './images/Sample.png';
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { TbBrandFiverr } from "react-icons/tb";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const teamMembers = [
  {
    name: "Donald Trump",
    position: "President",
    twitter: "https://twitter.com/donaldtrump",
    telegram: "https://t.me/donaldtrump",
    fiverr: "https://fiverr.com/donaldtrump"
  },
  {
    name: "Donald Trump2",
    position: "President2",
    twitter: "https://twitter.com/donaldtrump2",
    telegram: "https://t.me/donaldtrump2",
    fiverr: "https://fiverr.com/donaldtrump2"
  },
  {
    name: "Donald Trump3",
    position: "President3",
    twitter: "https://twitter.com/donaldtrump3",
    telegram: "https://t.me/donaldtrump3",
    fiverr: "https://fiverr.com/donaldtrump3"
  },
];

const TeamMemberCard = ({ name, position, twitter, telegram, fiverr }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const element = cardRef.current;

    // Scroll animation
    gsap.fromTo(element, { opacity: 0, scale: 0.8 }, {
      opacity: 1,
      scale: 1,
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        toggleActions: "play none none reverse",
      }
    });

    // Hover animation
    const handleMouseEnter = () => {
      gsap.to(element, {
        scale: 1,
        rotationY: 12,
        duration: 0.3,
      });
    };

    const handleMouseLeave = () => {
      gsap.to(element, {
        scale: 1,
        rotationY: 0,
        duration: 0.3,
      });
    };

    element.addEventListener('mouseenter', handleMouseEnter);
    element.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter);
      element.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <Grid item md={4} lg={4} xs={12} sm={12} ref={cardRef}>
    
      <div className='back'>
        <div className='cards'>
          <img src={sample} style={{ width: "100%" }} className='border_image_team' alt={name} />
          <br /><br />
          <h5 className='team_head'>{name}</h5>
          <h5 className='team_headu'>{position}</h5>
          <div style={{ display: "flex" }} className='makeitend'>
            <a href={twitter} className='all_righ' target="_blank" rel="noopener noreferrer">
              <FaXTwitter style={{ fontSize: "25px" }} />
            </a>
            &nbsp;&nbsp;
            <a href={telegram} className='all_righ' target="_blank" rel="noopener noreferrer">
              <FaTelegramPlane style={{ fontSize: "25px" }} />
            </a>
            &nbsp;&nbsp;
            <a href={fiverr} className='all_righ' target="_blank" rel="noopener noreferrer">
              <TbBrandFiverr style={{ fontSize: "25px" }} />
            </a>
          </div>
        </div>
      </div>
    </Grid>
  );
};

function Team() {
  return (
    <div className='container'>  <br/>  <br/>
      <h1 className='FAQ_TEXTsm'>Meet Our Experts</h1>  <br/>  <br/>
      <Grid container spacing={6}>
        {teamMembers.map(member => (
          <TeamMemberCard
            key={member.name}
            name={member.name}
            position={member.position}
            twitter={member.twitter}
            telegram={member.telegram}
            fiverr={member.fiverr}
          />
        ))}
      </Grid>
    </div>
  );
}

export default Team;
