import React from "react";
import Marquee from "react-fast-marquee";
function Marquees() {
  return (
    <Marquee
      style={{
        background: "#ff0000",
        paddingTop: "8px",
      }}
    >
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
      &nbsp;&nbsp;&nbsp;
      <h1 className="mem_mar">🪙 CLUTCH</h1>
    </Marquee>
  );
}

export default Marquees;
