import React from "react";
import Navbar from "./Navbar";
import HomeSlider from "./HomeSlider";
import Launchpad from "./Launchpad";
import Bots from "./Bots";
import About from "./About";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Marquees from "./Marquee";

function Desktop() {
  return (
    <div>
      <Navbar />
      <HomeSlider />
      <Marquees />
      <Launchpad />
      <Bots />
      <About />
      <FAQ />
      <Footer />
    </div>
  );
}

export default Desktop;
