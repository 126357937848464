import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@mui/material";
import moon from "./images/Tobo_aj9TgOUXcwRgRXSrw.webp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { BsPlusLg, BsFillQuestionDiamondFill } from "react-icons/bs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Marquee from "./Marquee";

gsap.registerPlugin(ScrollTrigger);

function FAQ() {
  const [expanded, setExpanded] = useState(null);
  const accordions = useRef([]); // To store refs for each accordion

  useEffect(() => {
    // GSAP Scroll-triggered animation for the accordion items
    accordions.current.forEach((accordion, index) => {
      gsap.fromTo(
        accordion,
        { opacity: 0, y: 50 }, // Initial state: faded and below
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: accordion,
            start: "top 80%", // Animation starts when the accordion is near the viewport
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  // Function to handle accordion expansion
  const handleAccordionChange = (panel, index) => (_, isExpanded) => {
    if (isExpanded) {
      animateBorder(index);
    }
    setExpanded(isExpanded ? panel : null);
  };

  // GSAP animation to make the border come in from the left and right
  const animateBorder = (index) => {
    gsap.fromTo(
      accordions.current[index],
      { borderLeftWidth: 0, borderRightWidth: 0 },
      {
        borderLeftWidth: "2px",
        borderRightWidth: "2px",
        duration: 1,
        ease: "power2.out",
      }
    );
  };

  // GSAP hover animation for borders
  const toggleHoverBorder = (index, isHovered) => {
    gsap.to(accordions.current[index], {
      borderLeftWidth: isHovered ? "2px" : "0px",
      borderRightWidth: isHovered ? "2px" : "0px",
      duration: 0.5,
      ease: "power2.out",
    });
  };

  const panels = [
    {
      id: "panel1",
      question: "How to Use the Solana Volume Bot",
      answer:
        "First, you need to start the Telegram volume bot. Then, enter your token address to access the main page where you can find your deposit wallet address. Deposit SOL to that wallet and click refresh to check the deposited SOL. Set your target volume, trade amount, and delay time if desired. Click the start button. The first initialization may take some time, after which the start button will change to a stop button. You can click refresh to get the status of the volume generated.",
    },
  ];

  const accordionStyle = (panel) => ({
    border: expanded === panel ? "2px solid #570074" : "0px solid transparent",
    transition: "border 0.5s ease-in-out",
    marginBottom: "20px", // Add spacing between accordions
  });

  const iconStyle = (panel) => ({
    fontSize: "27px",
    color: expanded === panel ? "#aeb0b2" : "#fff",
  });

  const textStyle = (panel) => ({
    color: expanded === panel ? "#aeb0b2" : "#fff",
  });

  return (
    <div className="back_of_faq">
      <Marquee />
      <div
        className="container"
        id="Faq"
        style={{ paddingTop: "100px", paddingBottom: "300px" }}
      >
        <Grid container spacing={1}>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <h1 className="FAQ_TEXT">FAQs</h1>
          </Grid>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            {panels.map((panel, index) => (
              <Accordion
                key={panel.id}
                expanded={expanded === panel.id}
                onChange={handleAccordionChange(panel.id, index)}
                ref={(el) => (accordions.current[index] = el)}
                onMouseEnter={() => toggleHoverBorder(index, true)}
                onMouseLeave={() => toggleHoverBorder(index, false)}
                style={accordionStyle(panel.id)}
                className="css-67l5gl"
              >
                <AccordionSummary
                  expandIcon={<BsPlusLg style={iconStyle(panel.id)} />}
                  aria-controls={`${panel.id}-content`}
                  id={`${panel.id}-header`}
                  style={textStyle(panel.id)}
                >
                  <h4 style={{ fontSize: "18px", color: "#ffbb00" }}>
                    {panel.question}
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="css-9l3uo3">{panel.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default FAQ;
