import React from 'react'



import Navbar from './NavbarMobile'
import HomeSlider from './HomeSliderMobile'
import Launchpad from './LaunchbadMobile'
import TopMarquee from './TopMarque'
import Bots from './BotsMobile'
import AboutMobile from './AboutMobile'

import FAQ from './FAQ'
import Footer from './FooterMobile'

function Desktop() {
  return (
    <div>
     <Navbar/>
      <HomeSlider/>
      <div className='toptop'><TopMarquee/>
      <Launchpad/>
      <Bots/></div>
      <AboutMobile/>
     
<FAQ/>
<Footer/>
    </div>
  )
}

export default Desktop